import { Link } from 'gatsby'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import DashboardLayout from '../../components/Layout/DashboardLayout'

const EditPersonalDetail = () => {
  return (
    <DashboardLayout>
      <Link to="/dashboard/personal-detail" className="back">
        Back To Information
      </Link>
      <Row>
        <Col xs={12} lg={6} className="edit-personal-detail">
          <h5>EDIT PERSONAL INFORMATION</h5>
          <Form.Group className="w-100 form-group mb-4" controlId="FirstName">
            <Form.Label className="text-sm">First Name *</Form.Label>
            <Form.Control type="text" placeholder="First name" />
          </Form.Group>
          <Form.Group className="w-100 form-group mb-4" controlId="Email">
            <Form.Label className="text-sm">Email *</Form.Label>
            <Form.Control type="email" placeholder="Your email" />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className="edit-personal-detail">
          <h5>CHANGE PASSWORD</h5>
          <Form.Group className="w-100 form-group mb-4" controlId="NewPassword">
            <Form.Label className="text-sm">New Password *</Form.Label>
            <Form.Control type="text" placeholder="Your new password" />
          </Form.Group>
          <Form.Group
            className="w-100 form-group mb-4"
            controlId="ConfirmNewPassword"
          >
            <Form.Label className="text-sm">Email *</Form.Label>
            <Form.Control type="email" placeholder="Confirm new password" />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Check
            type="checkbox"
            id="changeEmail"
            label="Change email"
            className="mb-2"
          />
          <Form.Check
            type="checkbox"
            id="changePassword"
            label="Change password"
            className="mb-2"
          />
        </Col>
      </Row>
      <a
        href="#"
        className="btn btn-outline-primary mx-auto d-flex btn-w140 mt-3"
      >
        Save
      </a>
    </DashboardLayout>
  )
}

export default EditPersonalDetail
